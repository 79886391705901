import { Link } from "gatsby"
import React from "react"

import arrowLeft from "../images/arrow_left.svg"
import arrowRight from "../images/arrow_right.svg"

const ProjectNav = ({ className, previous, next }) => (
  <div className={`content project-nav ${className || ""}`}>
    <div>
      <img className="margin-right" src={arrowLeft} alt="" />
      <Link to={previous}>Previous</Link>
    </div>
    <div className="align-right">
      <Link to={next}>Next</Link>
      <img className="margin-left" src={arrowRight} alt="" />
    </div>
  </div>
)

export default ProjectNav
